<template>
  <div>
    <Header />
    <div class="layout--full-page dark:bg-gray-800">
      <div class="antialiased bg-body text-body font-body">
        <router-view id="padding-scroll-content" class="square" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/modules/marketing/MarketingHeader.vue';
import Footer from '@/modules/marketing/MarketingFooter.vue';

export default {
  components: { Header, Footer },
};
</script>
